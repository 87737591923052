import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Logo from "./image"
import "../styles/global.css"

const Header = ({ siteTitle, pageSubtitle }) => (
  <header className="header-container">
    <div className="header-img">
      <Link to="/" style={{ textDecoration: `none`, color: `white` }}>
        <Logo />
    </Link>
    </div>

    <div className="bg-primary header-text">
      <h1 style={{ margin: 0, fontWeight: 500 }}>
        <Link to="/" style={{ textDecoration: `none`, color: `white` }}>
          { siteTitle }
        </Link>
      </h1>

      <h4 style={{ margin: `1em 0 0 0`, fontWeight: 500 }}>
        { pageSubtitle }
      </h4>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
