import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"


const Layout = ({ children, subtitle }) => {  
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <div className="page">
        <Header siteTitle={data.site.siteMetadata.title} pageSubtitle={ subtitle }/>

        <div className="page-content">
          { children }
        </div>
        
        <footer className="page-footer">
          © 2019 - { `${ new Date().getFullYear() } | Brenda Cuellar, ` }

          <a href="https://samuel-ramirez.com" target="_blank" rel="noopener noreferrer">
            Samuel Ramirez
          </a>
        </footer>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
